<script>
import "./MalfunctionsDetail.scss";
import MalfunctionsDetail from "./MalfunctionsDetail.js";

export default MalfunctionsDetail;
</script>

<style scoped>
.more_malfunctions > div {
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
}

.more_malfunctions > div:nth-child(odd) {
  background-color: #ccffff
}

.more_malfunctions > div:nth-child(even) {
  background-color: #f5f5f5;;
}

.malfunctionsDetail__content__item > div:first-child {
  padding: 10px;
}
</style>

<template>
  <div :class="['malfunctionsDetail', { 'is-urgent': theMalfunction.urgent }]">
    <div :class="['malfunctionsDetail__inner']">
      <header class="malfunctionsDetail__header">
        <div class="malfunctionsDetail__header__group">
          <span class="malfunctionsDetail__header__symbol">
            <Svg-icon iconType="Warning"></Svg-icon>
          </span>

          <span class="malfunctionsDetail__header__subtitle">Storing</span>
        </div>

        <h3 class="heading heading--3 malfunctionsDetail__header__title">
          {{ theMalfunction.category }}
        </h3>
      </header>

      <div class="malfunctionsDetail__content">

        <div class="malfunctionsDetail__content__item" style="max-height: 60vh;overflow: scroll;">
          <div>
            <div class="malfunctionsDetail__desc">
              <p>{{ theMalfunction.remarks }}</p>
            </div>
            <div class="malfunctionsDetail__visual" v-if="theMalfunction.image">
              <button @click="handlePhotoClick" class="button button--brand-1">
              <span class="button__symbol">
                <Svg-icon iconType="Camera-icon"></Svg-icon>
              </span>
                <span class="button__label">Bekijk foto</span>
              </button>
            </div>
          </div>


          <div class="more_malfunctions">
            <div v-for="detail in theMalfunction.details" ref="detail">
              <span v-html="detail.text"></span>
              <div class="malfunctionsDetail__visual" v-if="detail.image">
                <button @click="handlePhotoClick2(detail.image)" data-img="111" class="button button--brand-1">
                <span class="button__symbol">
                  <Svg-icon iconType="Camera-icon"></Svg-icon>
                </span>
                  <span class="button__label">Bekijk foto</span>
                </button>
              </div>
            </div>
            <div>

              <form class="malfunctionsForm__form">
                <div class="malfunctionsForm__form__field" ref="remark">
                  <textarea
                      v-model="inputFields.remarks"
                      class="input__textarea"
                      placeholder="Opmerkingen en notities voor verantwoodelijke en/of technieker"
                      rows="6"
                      name="remark"
                      style="margin-top: -16px;"
                  ></textarea>
                </div>

                <div class="malfunctionsForm__camera">
                  <transition name="fade">
                    <div
                        v-show="inputFields.image"
                        class="malfunctionsForm__camera__photo"
                    >
                      <img
                          :src="inputFields.image"
                          class="malfunctionsForm__camera__thePhoto"
                          ref="photo"
                      />

                      <button
                          @click.stop.prevent="handleDeleteClick"
                          class="malfunctionsForm__camera__photo__action"
                      >
                        <span class="malfunctionsForm__camera__photo__symbol">
                          <Svg-icon iconType="Trash"></Svg-icon>
                        </span>
                      </button>
                    </div>
                  </transition>

                  <Camera/>

                  <div class="malfunctionsForm__camera__btn">
                    <button
                        @click.stop.prevent="handlePhotoClick3"
                        class="button button&#45;&#45;pairing-medium"
                        ref="submit"
                    >
                              <span class="button__symbol">
                                <Svg-icon iconType="Camera-icon"/>
                              </span>
                      <span class="button__label">{{
                          !inputFields.image ? "Foto toevoegen" : "Foto vervangen"
                        }}</span>
                    </button>
                  </div>
                </div>

                <transition name="fade">
                  <div
                      v-if="isInvalid"
                      :class="{ 'is-invalid': isInvalid }"
                      class="malfunctionsForm__error"
                  >
                    <span class="input__error">
                      <span class="input__error__symbol">
                        <Svg-icon iconType="Warning"/>
                      </span>
                      <span class="input__error__label"
                      >Voeg een opmerking of foto toe</span
                      >
                    </span>
                  </div>
                </transition>

                <button
                    v-if="inputFields.remarks.length !== 0 "
                    @click="handleSubmit"
                    class="button button--pairing-positive"
                    style="margin-top: 10px;"
                >
                  <span class="button__label">Versturen</span>
                  <span class="button__symbol">
                  <Svg-icon iconType="Arrow-right"></Svg-icon>
                </span>
                </button>

              </form>


            </div>
          </div>
        </div>

        <div class="malfunctionsDetail__content__item">
          <div class="malfunctionsDetail__info">
            <ul class="malfunctionsDetail__info__list">
              <li class="malfunctionsDetail__info__listItem">
                <span class="malfunctionsDetail__info__title"
                >Datum &amp; tijd</span
                >

                <div class="malfunctionsDetail__info__data">
                  <span class="malfunctionsDetail__info__data__symbol">
                    <Svg-icon iconType="Calendar-flat"/>
                  </span>

                  <span
                      class="malfunctionsDetail__info__data__theContent ff--bold"
                  >
                    {{ formatDateOfMalfunction(theMalfunction) }}
                    <span>&bullet;</span>
                    {{ formatTimeOfMalfunction(theMalfunction) }}
                  </span>
                </div>
              </li>

              <li class="malfunctionsDetail__info__listItem">
                <span class="malfunctionsDetail__info__title"
                >Dienstregeling</span
                >

                <div class="malfunctionsDetail__info__data">
                  <span class="malfunctionsDetail__info__data__symbol">
                    <Svg-icon iconType="Anchor-circle"></Svg-icon>
                  </span>

                  <span class="malfunctionsDetail__info__data__theContent">{{
                      theFlightInfo.shift.name
                    }}</span>
                </div>
              </li>

              <li class="malfunctionsDetail__info__listItem">
                <span class="malfunctionsDetail__info__title">Kapitein</span>

                <div class="malfunctionsDetail__info__data">
                  <span class="malfunctionsDetail__info__data__symbol">
                    <Svg-icon iconType="Wheel"></Svg-icon>
                  </span>

                  <span class="malfunctionsDetail__info__data__theContent">{{
                      theMalfunction.captain.full_name
                    }}</span>
                </div>
              </li>

              <li
                  v-if="theMalfunction.urgent"
                  :class="[
                  'malfunctionsDetail__info__listItem',
                  { 'is-urgent': theMalfunction.urgent },
                ]"
              >
                <span class="malfunctionsDetail__info__title">Urgentie</span>

                <div class="malfunctionsDetail__info__data">
                  <span class="malfunctionsDetail__info__data__symbol"> </span>

                  <span class="malfunctionsDetail__info__data__theContent"
                  >Dringend</span
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
