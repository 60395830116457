import App from "../../_4-templates/App/App";
import axios from "axios";


export default {
  props: {
    viewData: Object,
  },

  data() {
    return {
      theMalfunction: this.viewData.malfunction,
      theFlightInfo: this.viewData.flightInfo,
      photoEl: null,
      inputFields: {
        remarks: "",
        image: null,
      },
      isInvalid: false,
    };
  },

  created() {
    this.theMalfunction.urgent = 1;
    this.inputFields.remarks = '';

    this.$store.commit({
      type: "setTmpImg",
      payload: null,
    });

    if (this.$store.state.newMalfunction) {
      this.inputFields.remarks = '';
    }

    this.photoEl = this.$refs.photo;

    // handle file upload
    this.emitter.on("fileIsUploaded", (isFileUploaded) => {
      if (!isFileUploaded) return false;

      this.$nextTick(() => this.onPhotoIsUploaded());
    });

    // enable next button
    this.emitter.emit(
        "create-malfunction--isDisabled",
        this.inputFields.remarks ? false : true
    );
  },

  methods: {
    handlePhotoClick() {
      const viewData = {
        title: this.theMalfunction.category,
        subtitle: "Storing",
        remarks: this.theMalfunction.remarks,
        image: this.theMalfunction.image,
      };

      this.emitter.emit("togglePhotoViewer", [true, viewData]);
    },
    handlePhotoClick2(img) {
      const viewData = {
        title: this.theMalfunction.category,
        subtitle: "Storing",
        remarks: this.theMalfunction.remarks,
        image: img,
      };


      this.emitter.emit("togglePhotoViewer", [true, viewData]);
    },

    handlePhotoClick3() {
      this.emitter.emit("openCamera", true);
    },

    onPhotoIsUploaded() {
      // $store.file is set on camera
      this.inputFields.image = this.$store.state.tmpImg;
      this.photoEl && (this.photoEl.src = this.$store.state.tmpImg);
    },

    handleDeleteClick() {
      this.inputFields.image = null;
      this.$store.state.tmpImg = null;
    },

    formatDateOfMalfunction(malfunction) {
      let theDate = malfunction.created_at.substring(
        0,
        malfunction.created_at.length - 8
      );

      let [yyyy, mm, dd] = theDate.split("-");

      theDate = `${yyyy}/${mm}/${dd}`;

      return theDate;
    },

    formatTimeOfMalfunction(malfunction) {
      let theTime = malfunction.created_at.substring(
        10,
        malfunction.created_at.length - 3
      );

      return theTime;
    },

    handleSubmit() {
        if (!this.inputFields.remarks) {
            this.isInvalid = true;
            return;
        }


        const formData = new FormData();
        formData.append("malfunction", this.theMalfunction.id);
        formData.append("remarks", this.inputFields.remarks);
        formData.append("image", this.inputFields.image);
        formData.append("captain", this.theMalfunction.captain.id);

      this.inputFields.remarks = '';
      this.inputFields.image = null;

        this.$store.commit({
            type: "setNewMalfunctionDetail",
            payload: {
            ...this.$store.state.newMalfunction,
            remarks: this.inputFields.remarks,
            image: this.inputFields.image,
            },
        });

      return axios.post(localStorage.getItem('LOCAL_URL') + '/malfunctions_detail/create', formData)
          .then(response => response)
          .catch(error => error.response.status !== 500 ? error.response : EventBus.$emit('toggleOffcanvas', true, 'Whoops'))
          .finally(() => {

            setTimeout(function () {
                location.reload();
            }, 3000);

            // this.emitter.emit("toggleOffcanvas", [false, "MalfunctionsDetail"]);
          });

       // this.emitter.emit("toggleOffcanvas", [false, "MalfunctionsDetail"]);

    }
  },
};
