import "../sass/main.scss";
import { createWebHistory, createRouter } from "vue-router";
import { createApp } from "vue";
import App from "./components/_4-templates/App/App.vue";
import { Store } from "vuex";
import { createStore } from "vuex-extensions";
import allAtoms from "./components/_1-atoms/all";
import allMolecules from "./components/_2-molecules/all";
import allOrganisms from "./components/_3-organisms/all";
import allTemplates from "./components/_4-templates/all";
import allSymbols from "./components/symbols/all";
import mitt from "mitt";
import Login from "./components/_4-templates/Login/Login.vue";
import Start from "./components/_4-templates/Start/Start.vue";
import Checklist from "./components/_4-templates/Checklist/Checklist.vue";
import Dashboard from "./components/_4-templates/Dashboard/Dashboard.vue";
import Help from "./components/_4-templates/Help/Help.vue";
import Notifications from "./components/_4-templates/Notifications/Notifications.vue";
import Malfunctions from "./components/_4-templates/Malfunctions/Malfunctions.vue";
import CreateMalfunction from "./components/_4-templates/CreateMalfunction/CreateMalfunction.vue";
import Flight from "./components/_4-templates/Flight/Flight.vue";
import FlightEdit from "./components/_4-templates/FlightEdit/FlightEdit.vue";
import FlightOverView from "./components/_4-templates/FlightOverview/FlightOverview.vue";
import UploadingData from "./components/_4-templates/UploadingData/UploadingData.vue";
import Vue3TouchEvents from "vue3-touch-events";
import { defaultStore } from "./config";

const emitter = mitt();
const app = createApp(App);

//init comps
app.use(Vue3TouchEvents);

//add components
allSymbols(app);
allAtoms(app);
allMolecules(app);
allOrganisms(app);
allTemplates(app);

app.config.globalProperties.emitter = emitter;

/*
 * Routing
 */

const routes = [
  {
    path: "/start/:step",
    name: "Start",
    component: Start,
  },
  {
    path: "/checklist",
    name: "Checklist",
    component: Checklist,
  },
  {
    path: "/checklist/:step",
    name: "Checklist-step",
    component: Checklist,
  },
  {
    path: "/checklist/:step/:substep",
    name: "Checklist-substep",
    component: Checklist,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
  },
  {
    path: "/malfunctions",
    name: "Malfunctions",
    component: Malfunctions,
  },
  {
    path: "/create-malfunction",
    name: "Create-malfunction",
    component: CreateMalfunction,
  },
  {
    path: "/create-malfunction/:step",
    name: "Create-malfunction-step",
    component: CreateMalfunction,
  },
  {
    path: "/help",
    name: "Help",
    component: Help,
  },
  {
    path: "/flight",
    name: "Flight",
    component: Flight,
  },
  {
    path: "/flight/edit",
    name: "Flight-edit",
    component: FlightEdit,
  },
  {
    path: "/flight-overview",
    name: "Flight-overview",
    component: FlightOverView,
  },
  {
    path: "/upload-data",
    name: "Upload-data",
    component: UploadingData,
  },
  {
    path: "/",
    name: "Login",
    component: Login,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

app.use(router);
//store

const store = createStore(Store, {
  state() {
    return localStorage.getItem("store")
      ? { ...JSON.parse(localStorage.getItem("store")), isOnline: true }
      : { ...defaultStore };
  },
  mixins: {
    mutations: {
      setWeather(state, payload) {
        state.weather = payload.payload;
        localStorage.setItem("store", JSON.stringify(state));
      },
      setSoundMp3(state, payload) {
        state.soundMp3 = payload.payload;
        localStorage.setItem("store", JSON.stringify(state));
      },
      setCaptainDetails(state, payload) {
        state.captainDetails = payload.payload;
        localStorage.setItem("store", JSON.stringify(state));
      },
      setAllTracks(state, payload) {
        state.allTracks = payload.payload;
        localStorage.setItem("store", JSON.stringify(state));
      },
      setAllShifts(state, payload) {
        state.allShifts = payload.payload;
        localStorage.setItem("store", JSON.stringify(state));
      },
      setAllShips(state, payload) {
        state.allShips = payload.payload;
        localStorage.setItem("store", JSON.stringify(state));
      },
      setAllCrew(state, payload) {
        state.allCrew = payload.payload;
        localStorage.setItem("store", JSON.stringify(state));
      },
      setAllStops(state, payload) {
        state.allStops = payload.payload;
        localStorage.setItem("store", JSON.stringify(state));
      },
      setCurrentStops(state, payload) {
        state.currentStops = payload.payload;
        localStorage.setItem("store", JSON.stringify(state));
      },
      setCurrentStopIndex(state, payload) {
        state.currentStopIndex = payload.payload;
        localStorage.setItem("store", JSON.stringify(state));
      },
      setCurrentStopsSendInfo(state, payload) {
        state.currentStopsSendInfo = {
          ...state.currentStopsSendInfo,
          ...payload.payload,
        };
        localStorage.setItem("store", JSON.stringify(state));
      },
      setFlightSettings(state, payload) {
        state.flightSettings = { ...state.flightSettings, ...payload.payload };
        localStorage.setItem("store", JSON.stringify(state));
      },
      setPassengers(state, payload) {
        state.passengers = { ...state.passengers, ...payload.payload };
        localStorage.setItem("store", JSON.stringify(state));
      },
      setIncompleteFlights(state, payload) {
        state.incompleteFlights = payload.payload;
        localStorage.setItem("store", JSON.stringify(state));
      },
      setChecklistSelectedOptions(state, payload) {
        state.checklistSelectedOptions = payload.payload;
      },
      setChecklist(state, payload) {
        state.checklist = payload.payload;
        localStorage.setItem("store", JSON.stringify(state));
      },
      setChecklistValues(state, payload) {
        state.checklistValues = payload.payload;
      },
      setChecklistRemarks(state, payload) {
        state.checklistRemarks = payload.payload;
      },
      setChecklistCompletedLists(state, payload) {
        state.checklistCompletedLists = payload.payload;
      },
      setChecklistIsSend(state, payload) {
        state.checklistIsSend = payload.payload;
        localStorage.setItem("store", JSON.stringify(state));
      },
      setChecklistIsSending(state, payload) {
        state.checklistIsSending = payload.payload;
        localStorage.setItem("store", JSON.stringify(state));
      },
      setChecklistIsUpdated(state, payload) {
        state.checklistIsUpdated = payload.payload;
        localStorage.setItem("store", JSON.stringify(state));
      },
      setNotifications(state, payload) {
        state.notifications = payload.payload;
        localStorage.setItem("store", JSON.stringify(state));
      },
      setReadNotifications(state, payload) {
        state.readNotifications = payload.payload;
        localStorage.setItem("store", JSON.stringify(state));
      },
      setReadNotificationSendInfo(state, payload) {
        state.readNotificationSendInfo = payload.payload;
        localStorage.setItem("store", JSON.stringify(state));
      },
      setMalfunctions(state, payload) {
        state.malfunctions = payload.payload;
        localStorage.setItem("store", JSON.stringify(state));
      },
      setNewMalfunction(state, payload) {
        state.newMalfunction = payload.payload;
        localStorage.setItem("store", JSON.stringify(state));
      },
      setNewMalfunctionDetail(state, payload) {
        state.newMalfunctionDetail = payload.payload;
        localStorage.setItem("store", JSON.stringify(state));
      },
      setNewMalfunctionSendInfo(state, payload) {
        state.newMalfunctionSendInfo = payload.payload;
        localStorage.setItem("store", JSON.stringify(state));
      },
      setCurrentFlight(state, payload) {
        state.currentFlight = payload.payload;
        localStorage.setItem("store", JSON.stringify(state));
      },
      setTmpImg(state, payload) {
        state.tmpImg = payload.payload;
        localStorage.setItem("store", JSON.stringify(state));
      },
      setIsCompleted(state, payload) {
        state.isCompleted = payload.payload;
        localStorage.setItem("store", JSON.stringify(state));
      },
      resetCheckList(state) {
        state.checklist = null;
        state.checklistValues = [];
        state.checklistRemarks = [];
        state.checklistSelectedOptions = [];
        state.checklistCompletedLists = [];
        state.checklistIsSending = false;
        state.checklistIsSend = false;

        localStorage.setItem("store", JSON.stringify(state));
      },
      clearStore(state) {
        Object.assign(state, defaultStore);

        localStorage.setItem("store", JSON.stringify(state));
      },
      saveStore(state) {
        localStorage.setItem("store", JSON.stringify(state));
      },
      setStoreToOldStore(state, payload) {
        if (payload.payload) {
          Object.assign(state, payload.payload);

          localStorage.setItem("store", JSON.stringify(state));
        }
      },
    },
  },
});

router.beforeEach((to, from) => {
  if (to === "/") {
    //reset
    store.commit({
      type: "clearStore",
      payload: {},
    });
  }

  return true;
});

window.addEventListener("online", () => (store.state.isOnline = true));
window.addEventListener("offline", () => (store.state.isOnline = false));

window.onbeforeunload = function () {
  alert("You are now leaving, are you sure?");
};

app.use(store);

//mount app
app.mount("#app");
