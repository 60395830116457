import Checklists from "../../../api/Checklists";
import Flights from "../../../api/Flights";
import Malfunctions from "../../../api/Malfunctions";
import Notifications from "../../../api/Notifications";
import Stops from "../../../api/Stops";
import soundMp3 from "../../../../sounds/CockpitApp_Notification.mp3";

export default {
  data() {
    return {
      showOverlay: false,
      lastupdate: -1,
      updateDelay: 50,
    };
  },
  created() {
    //remove oldStore if completed
    this.validateOldStore();

    //rest all sending values
    this.resetSending();

    //check for login token otherwise go to login
    this.checkLogin();

    //if init present
    this.sendAll();

    this.emitter.on("app--sendFlightSettings", () => {
      this.sendFlightSettings();
    });

    this.emitter.on("app--sendCheckist", () => {
      this.sendChecklist();
    });

    this.emitter.on("app--sendNewMalfunction", () => {
      this.sendNewMalfunction();
    });
    this.emitter.on("app--sendNewMalfunctionDetail", () => {
      this.sendNewMalfunctionDetail();
    });

    this.emitter.on("toggleOffcanvas", (data) => {
      this.showOverlay = data[0];
    });

    this.emitter.on("app--getNotifications", () => {
      this.getNotifications();
    });

    this.emitter.on("app--getMalfunctions", () => {
      this.getMalfunctions();
    });

    this.emitter.on("app--getStops", () => {
      this.getStops();
    });

    this.emitter.on("app--sendCurrentStops", () => {
      //this.lastupdate = -1;
      //this.sendCurrentStops();
      //this.sendAll();
    });

    this.emitter.on("app--sendAll", () => {
      //this.lastupdate = -1;
      //this.sendAll();
    });

    //get notifications and malfunctions every 5 minutes
    this.getNotifications();
    this.getMalfunctions();
    this.getStops(true);
    this.getsound();

    setInterval(() => {
      //this.lastupdate = -1;
      this.getMalfunctions();
      this.getNotifications();
      this.sendAll();
    }, 30000);
  },
  methods: {
    validateOldStore() {
      const oldStore = JSON.parse(localStorage.getItem("oldStore"));

      if (oldStore) {
        if (
          (oldStore.isComplete ||
            this.$store.state.currentStopsSendInfo.isSend) &&
          oldStore.checklistIsSend
        ) {
          //remove old store because completed and logout user?
          localStorage.removeItem("oldStore");

          //reset store to default
          this.$store.commit({
            type: "clearStore",
            payload: {},
          });

          localStorage.removeItem("store");
          localStorage.removeItem("token");
          localStorage.removeItem("concession");

          this.$router.push("/");
        }
      }
    },

    sendAll() {
      this.sendFlightSettings();
      //this.addDelay(this.sendCurrentStops(), 100);
      this.addDelay(this.sendChecklist(), 100);
      this.addDelay(this.sendNewMalfunction(), 200);
      this.addDelay(this.sendNotifications(), 300);
    },
    addDelay(func, delay) {
      setTimeout(() => {
        func;
      }, delay);
    },
    resetSending() {
      this.$store.commit({
        type: "setFlightSettings",
        payload: { isSending: false },
      });

      this.$store.commit({
        type: "setChecklistIsSending",
        payload: false,
      });

      this.$store.commit({
        type: "setNewMalfunction",
        payload: {
          ...this.$store.state.newMalfunction,
          isSending: false,
        },
      });


      this.$store.commit({
        type: "setCurrentStopsSendInfo",
        payload: { updated: false, isSending: false, isSend: false },
      });
    },
    checkLogin() {
      if (!localStorage.getItem("token") && this.$route.path !== "/") {
        this.$router.push("/");
      }
    },

    sendFlightSettings() {
      const settings = { ...this.$store.state.flightSettings };
      if (!settings.isSending) {
        //is not send yet
        //check if values are entered
        let hasAllValues = true;
        Object.keys(settings).forEach((key) => {
          if (
            key !== "id" &&
            key !== "isSend" &&
            key !== "isSending" &&
            (settings[key] === null || settings[key] === [])
          ) {
            hasAllValues = false;
          }
        });

        //all values are set send the data
        if (hasAllValues) {
          //get position

          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (pos) => {
                this.sendFlight(settings, pos);
              },
              (err) => {
                this.sendFlight(settings);
              }
            );
          } else {
            this.sendFlight(settings);
          }
        }
      }
    },

    sendFlight(settings, pos = false) {
      if (
        this.$store.state.isOnline &&
        !this.$store.state.flightSettings.isSending &&
        !this.$store.state.flightSettings.isSend &&
        settings.track &&
        settings.shift &&
        settings.ship &&
        settings.crew &&
        localStorage.getItem("token")
      ) {
        const formData = new FormData();

        this.$store.commit({
          type: "setFlightSettings",
          payload: { isSending: true },
        });

        if (pos) {
          formData.append("lon", pos.coords.longitude);
          formData.append("lat", pos.coords.latitude);
        } else {
          formData.append("lon", 1);
          formData.append("lat", 1);
        }

        formData.append("track", settings.track.id);
        formData.append("shift", settings.shift.id);
        formData.append("ship", settings.ship.id);
        formData.append(
          "stewards",
          JSON.stringify(settings.crew.map((steward) => steward.id))
        );

        if (settings.id) {
          formData.append("flight", settings.id);

          //has id so update
          Flights.update(formData, this)
            .then((r) => this.onSuccess(r, "updated"))
            .catch((e) => {
              //this.onFail(e);
              this.$store.commit({
                type: "setFlightSettings",
                payload: { isSending: false },
              });
            });
        } else {
          //has no id so create
          Flights.create(formData, this)
            .then((r) => this.onSuccess(r, "created"))
            .catch((e) => {
              //this.onFail(e);
              this.$store.commit({
                type: "setFlightSettings",
                payload: { isSending: false },
              });
            });
        }

        this.getCurrentStops();
      }
    },

    onSuccess(r, api) {
      const flight = JSON.parse(r.data.body.flight);

      this.$store.commit({
        type: "setFlightSettings",
        payload: { isSending: false, isSend: true },
      });

      if (api === "created") {
        //is created
        //set the id of the saved flight
        this.$store.commit({
          type: "setFlightSettings",
          payload: { id: flight.id },
        });
      }

      this.getCurrentStops();
    },
    getCurrentStops() {
      //get stops

      if (this.$store.state.flightSettings.id) {
        Flights.detail(this.$store.state.flightSettings.id)
          .then((r) => {
            let isUpdated = false;
            let newItems = r.data.body.all_items;

            if (this.$store.state.currentStops.length) {
              newItems = [...this.$store.state.currentStops]
                .map((currentStop) => {
                  let newItem = currentStop;

                  //already has items
                  r.data.body.all_items.forEach((item) => {
                    if (currentStop.departure === item.departure) {
                      //this one
                      newItem = {
                        ...item,
                        ...currentStop,
                        id: item.id,
                        flight_id: item.flight_id,
                        stop: item.stop,
                        why_skipped: item.why_skipped ? why_skipped : null,
                        updated_at: item.updated_at,
                        created_at: item.created_at,
                        isSending: false,
                        isSend: true,
                      };
                    }
                  });

                  return { ...newItem };
                })
                .sort((a, b) => {
                  if (a.departure > b.departure) {
                    return 1;
                  }

                  if (a.departure < b.departure) {
                    return -1;
                  }

                  return 0;
                });
            }

            //got the stops
            this.$store.commit({
              type: "setCurrentStops",
              payload: newItems,
            });

            //is updated
            if (isUpdated) {
              this.$store.commit({
                type: "setCurrentStopsSendInfo",
                payload: { updated: true, isSending: false },
              });
            }

            //set init index
            let initIndex = this.$store.state.currentStopIndex;
            let isFirst = false;

            newItems.forEach((item, index) => {
              if (
                (!item.arrived || !item.passed) &&
                !item.skipped &&
                initIndex === -1 &&
                index !== 0
              ) {
                initIndex = index;
              } else if (
                index === 0 &&
                (!item.arrived || !item.passed) &&
                !item.skipped
              ) {
                isFirst = true;
              }
            });

            if (!isFirst) {
              this.$store.commit({
                type: "setCurrentStopIndex",
                payload: initIndex,
              });
            } else {
              //set init passengers
              const data = r.data.body.all_items[0];

              if (data) {
                this.$store.commit({
                  type: "setPassengers",
                  payload: {
                    ...this.$store.state.passengers,
                    current_people: parseInt(data.people),
                    current_bikes: parseInt(data.bikes),
                  },
                });
              }
            }
          })
          .catch((error) => {
            //go back to login if online otherwise ignore
            if (this.$store.state.isOnline) {
              this.resetTokens();
            }
          });
      }
    },
    onFail(r) {
      let theMessage = {};

      theMessage.status = r.status;
      theMessage.title = r.message;
      theMessage.subtitle = "Oeps?!";
      theMessage.body = "Steps error.";
      theMessage.icon = "radar";

      this.$store.commit({
        type: "setFlightSettings",
        payload: { isSending: false, isSend: false },
      });

      //this.emitter.emit("toggleOffcanvas", [true, "Whoops", theMessage]);
    },

    updateChecklist(checklistId) {
      const promises = [];

      console.log("update", checklistId);

      if (!this.$store.state.checklistIsSending) {
        this.$store.commit({
          type: "setChecklistIsSending",
          payload: true,
        });

        this.$store.state.checklist.checklist.forEach((checklist) => {
          checklist.subcategories.forEach((subcat) => {
            subcat.fields.forEach((field) => {
              const fieldId = field.field ? field.field.id : field.field_id;
              const formData = new FormData();

              formData.append("checklist", checklistId);
              formData.append("checked", 1);
              formData.append("field_id", fieldId);
              formData.append("entry", field.id);

              const unit = field.field ? field.field.unit : field.unit;

              if (unit !== "n.v.t") {
                const value = [...this.$store.state.checklistValues].filter(
                  (checkValue) => {
                    if (checkValue) {
                      return (
                        checkValue.id === field.field_id ||
                        checkValue.id === field.id
                      );
                    }
                    return false;
                  }
                )[0];
                //has value

                formData.append("value", value ? value.value : 0);
              }

              const remark = [...this.$store.state.checklistRemarks].filter(
                (value) => value.id === field.id
              )[0];
              if (remark) {
                formData.append("remarks", remark.remark);
                formData.append("image", remark.img);
              }

              promises.push(
                Checklists.update(formData, this)
                  .then((r) => {
                    //is updated
                    this.updateChecklistProperties(true);
                  })
                  .catch((e) => {
                    //remove id
                    const checklist = this.$store.state.checklist;

                    this.$store.commit({
                      type: "setChecklist",
                      payload: {
                        ...checklist,
                        id: null,
                      },
                    });

                    this.updateChecklistProperties(false);

                    return e;
                  })
              );
            });
          });
        });
      }
      return promises;
    },

    checkIfStopsAreSet() {
      const stopsSet =
        this.$store.state.currentStops.filter((stop) => {
          if (stop) {
            //check if passed and has all data of skipped (no data needed)
            if (
              (stop.departured && stop.arrived && stop.passed) ||
              stop.skipped
            ) {
              return false;
            }
          }

          return true;
        }).length === 0;

      this.$store.commit({
        type: "setIsCompleted",
        payload: stopsSet,
      });

      return stopsSet;
    },

    createCheckList() {
      console.log("create");
      //first create a checklist then update it
      const formData = new FormData();
      const settings = { ...this.$store.state.flightSettings };
      const promises = [];
      console.log("create", settings.id, !this.$store.state.checklistIsSending);
      if (settings.id && !this.$store.state.checklistIsSending) {
        formData.append("ship", settings.ship.id);
        formData.append("shift", settings.shift.id);
        formData.append("track", settings.track.id);
        formData.append("start_stop", this.checkIfStopsAreSet() ? 1 : 0);
        formData.append("flight", settings.id);

        this.$store.commit({
          type: "setChecklistIsSending",
          payload: true,
        });
        console.log("trying to create");
        const created = new Promise((resolve, reject) => {
          Checklists.create(formData)
            .then((r) => {
              const checklist = JSON.parse(r.data.body);

              //set the new id
              this.$store.commit({
                type: "setChecklist",
                payload: {
                  ...checklist,
                },
              });

              console.log("created");

              this.$store.commit({
                type: "setChecklistIsSending",
                payload: false,
              });

              setTimeout(() => {
                this.updateChecklist(checklist.id);
              }, 100);
            })
            .catch((e) => {
              reject();
            });
        });

        promises.push(created);
      }

      return promises;
    },

    sendChecklist() {
      console.log(
        this.$store.state.isOnline,
        this.$store.state.checklist,
        !this.$store.state.checklistIsSend,
        !this.$store.state.checklistIsSending,
        this.$store.state.checklistIsUpdated
      );
      if (
        this.$store.state.isOnline &&
        this.$store.state.checklist &&
        !this.$store.state.checklistIsSend &&
        !this.$store.state.checklistIsSending &&
        this.$store.state.checklistIsUpdated
      ) {
        const checklistId = this.$store.state.checklist.id;
        //use update not complete
        const promises = [];

        this.$store.commit({
          type: "setChecklistIsSending",
          payload: true,
        });

        //check if id exists
        if (checklistId) {
          Checklists.detail(checklistId)
            .then(() => {
              this.$store.commit({
                type: "setChecklistIsSending",
                payload: false,
              });
              this.updateChecklist(checklistId);
            })
            .catch((e) => {
              this.$store.commit({
                type: "setChecklistIsSending",
                payload: false,
              });

              setTimeout(() => {
                this.createCheckList();
              }, 100);
            });
        } else {
          this.$store.commit({
            type: "setChecklistIsSending",
            payload: false,
          });

          setTimeout(() => {
            this.createCheckList();
          }, 100);
        }
        /* if (checklistId) {
          //has existing field
          promises.concat(this.updateChecklist(checklistId));
        } else {
          promises.concat(this.createCheckList());
        }*/

        /*Promise.all(promises)
          .then((r) => {
            //all send
            //set as send
            this.$store.commit({
              type: "setChecklistIsSend",
              payload: true,
            });

            this.$store.commit({
              type: "setChecklistIsUpdated",
              payload: false,
            });

            this.$store.commit({
              type: "setChecklistIsSending",
              payload: false,
            });

            //save to old store
            localStorage.setItem("oldStore", JSON.stringify(this.$store.state));
          })
          .catch((e) => {
            console.log("my error");
            this.$store.commit({
              type: "setChecklistIsSending",
              payload: false,
            });
          });*/
      }
    },

    updateChecklistProperties(success = false) {
      console.log("Testing update");

      if (success) {
        console.log("update");

        //all send
        //set as send
        this.$store.commit({
          type: "setChecklistIsSend",
          payload: true,
        });

        this.$store.commit({
          type: "setChecklistIsUpdated",
          payload: false,
        });

        this.$store.commit({
          type: "setChecklistIsSending",
          payload: false,
        });

        //save to old store

        //localStorage.setItem("oldStore", JSON.stringify(this.$store.state));
      } else {
        console.log("my error");
        this.$store.commit({
          type: "setChecklistIsSend",
          payload: false,
        });

        this.$store.commit({
          type: "setChecklistIsSending",
          payload: false,
        });
      }
    },
    resetTokens() {
      /*localStorage.removeItem("token");
      localStorage.removeItem("concession");

      this.$router.push("/");*/
    },
    getNotifications() {
      if (
        this.$store.state.flightSettings.track &&
        localStorage.getItem("token") &&
        this.$store.state.isOnline
      ) {
        Notifications.all(this.$store.state.flightSettings.track.id, this)
          .then((r) => {
            const notifications =
              typeof r.data.body === "object"
                ? r.data.body
                : JSON.parse(r.data.body);

            this.$store.commit({
              type: "setNotifications",
              payload: notifications.filter(
                (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
              ),
            });
          })
          .catch((error) => {
            // this.emitter.emit("toggleOffcanvas", [true, "Whoops"])
            //go back to login if online otherwise ignore
            if (this.$store.state.isOnline) {
              this.resetTokens();
            }
          });
      }
    },
    getMalfunctions() {
      if (
        this.$store.state.flightSettings.ship &&
        localStorage.getItem("token") &&
        this.$store.state.isOnline
      ) {
        Malfunctions.overview(this.$store.state.flightSettings.ship.id, this)
          .then((r) => {
            this.$store.commit({
              type: "setMalfunctions",
              payload: JSON.parse(r.data.body),
            });
          })
          .catch((error) => {
            // this.emitter.emit("toggleOffcanvas", [true, "Whoops"])
            //go back to login if online otherwise ignore

            if (this.$store.state.isOnline) {
              this.resetTokens();
            }
          });
      }
    },
    sendNotifications() {
      if (this.$store.state.readNotificationSendInfo) {
        const sendInfo = this.$store.state.readNotificationSendInfo;

        if (
          sendInfo.updated &&
          !sendInfo.isSending &&
          this.$store.state.isOnline
        ) {
          //set as sending
          this.$store.commit({
            type: "setReadNotificationSendInfo",
            payload: {
              ...sendInfo,
              isSending: true,
            },
          });

          //send items
          const promises = [];

          sendInfo.needsSending.forEach((notification) => {
            promises.push(
              Notifications.read(
                this.$store.state.flightSettings.track.id,
                notification.id
              )
            );
          });

          Promise.all(promises).then((r) => {
            this.getNotifications();

            //filter sendInfo.needsSending
            const currentNotificationsForSending =
              this.$store.state.readNotificationSendInfo.needsSending;

            this.$store.commit({
              type: "setReadNotificationSendInfo",
              payload: {
                ...sendInfo,
                needsSending: currentNotificationsForSending.filter((note) => {
                  let needsDeleting = false;

                  sendInfo.needsSending.forEach((toRemove) => {
                    if (toRemove.id === note.id) {
                      needsDeleting = true;
                    }
                  });

                  return !needsDeleting;
                }),
                isSending: false,
                updated: false,
              },
            });
          });
        }
      }
    },
    sendNewMalfunctionDetail() {
      alert(2);
    },
    sendNewMalfunction() {
      if (this.$store.state.newMalfunctionSendInfo) {
        const sendInfo = this.$store.state.newMalfunctionSendInfo;

        if (
          sendInfo.updated &&
          !sendInfo.isSending &&
          this.$store.state.isOnline
        ) {
          //set as sending
          this.$store.commit({
            type: "setNewMalfunctionSendInfo",
            payload: {
              ...sendInfo,
              isSending: true,
            },
          });

          //send items
          const promises = [];

          sendInfo.needsSending.forEach((malfunc) => {
            const settings = this.$store.state.flightSettings;

            const formData = new FormData();

            formData.append("flight", settings.id);
            formData.append("ship", settings.ship.id);
            formData.append("shift", settings.shift.id);

            formData.append("category", (malfunc.situation == undefined ? null : malfunc.situation.title));
            formData.append("remarks", malfunc.remarks);
            formData.append("image", malfunc.image);

            if(malfunc.situation!=undefined) {
              console.log('push');
              promises.push(Malfunctions.create(formData));
            }else{
              console.log('end');
            }
          });

          Promise.all(promises).then((r) => {
            this.getMalfunctions();

            //filter sendInfo.needsSending
            const currentMalfuncsForSending =
              this.$store.state.newMalfunctionSendInfo.needsSending;

            console.log("currentMalfuncsForSending");

            this.$store.commit({
              type: "setNewMalfunctionSendInfo",
              payload: {
                ...sendInfo,
                needsSending: currentMalfuncsForSending.filter((malfunc) => {
                  let needsDeleting = false;

                  sendInfo.needsSending.forEach((toRemove) => {
                    if(toRemove.situation == undefined){
                      needsDeleting = true;
                    }else {
                      if (toRemove.situation && toRemove.situation.title && malfunc.situation) {
                        try {
                          if (toRemove.situation.title === malfunc.situation.title) needsDeleting = true;
                        } catch (e) {
                          needsDeleting = true;
                        }
                      }
                    }
                  });

                  return !needsDeleting;
                }),
                isSending: false,
                updated: false,
              },
            });
          });
        }
      }
    },
    getStops(init = false) {
      if (
        this.$store.state.isOnline &&
        (init || !this.$store.state.allStops.length) &&
        localStorage.getItem("token")
      ) {
        //get init items or is empty
        Stops.overview()
          .then((r) => {
            this.$store.commit({
              type: "setAllStops",
              payload: JSON.parse(r.data.body),
            });
            console.log("stops", JSON.parse(r.data.body));
            //set current index to first availible option
          })
          .catch((error) => {
            // this.emitter.emit("toggleOffcanvas", [true, "Whoops"])
            //go back to login if online otherwise ignore
            if (this.$store.state.isOnline) {
              this.resetTokens();
            }
          });
      }
    },
    sendCurrentStops() {
      /*const sendInfo = this.$store.state.currentStopsSendInfo;
      const timestamp = new Date().getTime();
      const existingStop = this.$store.state.currentStops[0]
        ? this.$store.state.currentStops[0].id
        : false;

      let allSend = true;
      this.$store.state.currentStops.forEach((currentStop) => {
        let stopSend = false;
        this.$store.state.currentStopsSendInfo.succesfullySend.forEach(
          (sendStop) => {
            if (currentStop) {
              if (sendStop.id === currentStop.id) {
                stopSend = true;
              }
            }
          }
        );
        if (currentStop) {
          if (!stopSend && currentStop.arrived) {
            allSend = false;
          }
        }
      });

      if (
        (sendInfo.updated || !allSend) &&
        !sendInfo.isSending &&
        this.$store.state.isOnline &&
        this.lastupdate < timestamp + this.updateDelay &&
        existingStop
      ) {
        //set last update
        this.lastupdate = timestamp;

        //set as sending
        this.$store.commit({
          type: "setCurrentStopsSendInfo",
          payload: { isSending: true },
        });

        //send all currentStops data
        const promises = [];
        const passItems = [];

        this.$store.state.currentStops.forEach((stop) => {
          if (
            !sendInfo.succesfullySend.filter(
              (successSend) => successSend.id === stop.id
            ).length &&
            (stop.id !== null || stop.inserted)
          ) {
            //if is inserted and send

            if (
              (stop.passed || stop.arrived) &&
              ((stop.inserted && stop.isSend) || !stop.inserted)
            ) {
              const stopData = {
                item: stop.id,
              };
              const keys = [
                "id",
                "arrived",
                "departured",
                "people_on",
                "people_off",
                "bikes_on",
                "bikes_off",
                "people",
                "bikes",
              ];

              keys.forEach((key) => {
                stopData[key] = stop[key];
              });

              passItems.push(stopData);
            } else if (
              sendInfo.needsRemoving.filter((item) => item.item === stop.id)
                .length > 0
            ) {
              const deleteInfo = sendInfo.needsRemoving.filter(
                (item) => item.item === stop.id
              )[0];
              //needs to be removed
              const formData = new FormData();

              Object.keys(deleteInfo).forEach((key) => {
                formData.append(key, deleteInfo[key]);
              });

              promises.push(
                Items.skip(formData).catch((err) => {
                  throw { err, myError: "Skip error" };
                })
              );
            } else if (stop.inserted && !stop.isSending && !stop.isSend) {
              //insert as soon as created and if send don't add again
              const currentStops = [...this.$store.state.currentStops];

              //set current stop to sending
              this.$store.commit({
                type: "setCurrentStops",
                payload: currentStops.map((item) => {
                  if (item.id === stop.id) {
                    return {
                      ...item,
                      isSending: true,
                    };
                  }

                  return item;
                }),
              });

              //is new item that has not been inserted yet
              const formData = new FormData();

              formData.append("flight", this.$store.state.flightSettings.id);
              formData.append("after", stop.after_index);
              formData.append("stop", stop.stop_id);
              formData.append("departure", stop.departure);

              promises.push(
                Items.insert(formData)
                  .then((r) => {
                    const allItems = JSON.parse(r.data.body.items);

                    const currentStops = [...this.$store.state.currentStops];

                    const insertedStop = allItems.filter(
                      (item) => item.departure === stop.departure
                    )[0];

                    if (insertedStop) {
                      //set current stop to send
                      this.$store.commit({
                        type: "setCurrentStops",
                        payload: currentStops.map((item) => {
                          if (item.id === stop.id) {
                            return {
                              ...insertedStop,
                              ...stop,
                              id: insertedStop.id,
                              //id: stop.id,
                              //insertedId: insertedStop.id,
                              isSending: false,
                              isSend: true,
                            };
                          }

                          return item;
                        }),
                      });
                    } else {
                      this.$store.commit({
                        type: "setCurrentStops",
                        payload: currentStops.map((item) => {
                          if (item.id === stop.id) {
                            return {
                              ...item,
                              isSending: false,
                            };
                          }

                          return item;
                        }),
                      });
                    }
                  })
                  .catch((err) => {
                    const currentStops = [...this.$store.state.currentStops];
                    //set current stop to not sending
                    this.$store.commit({
                      type: "setCurrentStops",
                      payload: currentStops.map((item) => {
                        if (item.id === stop.id) {
                          return {
                            ...item,
                            isSending: false,
                          };
                        }

                        return item;
                      }),
                    });

                    throw { err, myError: "insert error" };
                  })
              );
            }
          }
        });

        //add passed items in one
        const formData = new FormData();

        formData.append("flight", this.$store.state.flightSettings.id);
        formData.append("items", JSON.stringify(passItems));

        promises.push(
          Items.passMutiple(formData)
            .then((r) => {
              const currentStops = [...this.$store.state.currentStops].map(
                (item) => item.inserted
              );
              const passedItems = [
                ...JSON.parse(r.data.body.items),
                ...currentStops,
              ].filter((item) => (item.passed && item.arrived) || item.skipped);

              this.$store.commit({
                type: "setCurrentStopsSendInfo",
                payload: {
                  succesfullySend: passedItems,
                },
              });
            })
            .catch((err) => {
              throw { err, myError: "Pass error" };
            })
        );

        Promise.all(promises)
          .then((r) => {
            this.$store.commit({
              type: "setCurrentStopsSendInfo",
              payload: { updated: false, isSending: false },
            });

            this.getCurrentStops();
          })
          .catch((e) => {
            this.$store.commit({
              type: "setCurrentStopsSendInfo",
              payload: { updated: true, isSending: false },
            });
          });
      } else if (!existingStop) {
        //the stops don't have id's so find and add these
        this.getCurrentStops();
      }

      this.getCurrentStops();*/
    },
    getsound() {
      if (this.$store.state.isOnline) {
        this.$store.commit({
          type: "setSoundMp3",
          payload: new Audio(soundMp3),
        });
      }
    },
  },
  watch: {
    $route() {
      this.checkLogin();
    },
    "$store.state.isOnline"(newVal) {
      if (newVal) {
        this.sendAll();
        this.getStops();
        this.addDelay(this.getNotifications(), 50);
        this.addDelay(this.getMalfunctions(), 100);
        this.addDelay(this.getsound(), 150);
      }
    },
    "$store.state.incompleteFlights"() {
      this.getCurrentStops();
    },

    "$store.state.currentStopsSendInfo"() {
      //this.sendCurrentStops();
    },
    "$store.state.newMalfunctionSendInfo"() {
      this.sendNewMalfunction();
    },
    "$store.state.newMalfunctionDetailSendInfo"() {
      this.sendNewMalfunctionDetail();
    },
  },
};
